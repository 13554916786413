import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Donors from "./Donors";
import Button from "../elements/Button";
import Tabs, { TabList, Tab } from "./../elements/Tabs";
import { withTranslation } from "react-i18next";
import Input from "../elements/Input";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class TodaysDonations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      donorsList: [],
      privateDonorsList: [],
      companyDonorsList: [],
      currentPrivateDonorsList: [],
      currentCompanyDonorsList: [],
      showPrivate: true,
      page: 0,
      filterText: "",
    };

    this.handleShowPrivate = this.handleShowPrivate.bind(this);
    this.handleFilterTextInput = this.handleFilterTextInput.bind(this);
    this.getFilteredLists = this.getFilteredLists.bind(this);
  }

  componentDidUpdate() {
    if (this.state.donorsList !== this.props.donors) {
      this.setState({ donorsList: this.props.donors });
      let privateList = [];
      let companiesList = [];
      this.props.donors.forEach((donationItem) => {
        if (donationItem.donorType === "private") {
          privateList.push(donationItem);
        } else {
          companiesList.push(donationItem);
        }
      });

      this.setState({
        privateDonorsList: privateList,
        companyDonorsList: companiesList,
        currentPrivateDonorsList: privateList.slice(0, 5),
        currentCompanyDonorsList: companiesList.slice(0, 5),
      });
    }
  }

  handleShowPrivate(isPrivate) {
    this.setState({ showPrivate: isPrivate });
    this.resetCurrentLists();
  }

  handleShowMore(page, list) {
    const numberOfVisibleItems = (page + 1) * 5;
    let slicedList = this.getFilteredLists(list, this.state.filterText);
    if (list.length > numberOfVisibleItems) {
      slicedList = list.slice(0, numberOfVisibleItems);
    }

    const newState = this.state.showPrivate
      ? {
          ...this.state,
          page,
          currentPrivateDonorsList: slicedList,
        }
      : {
          ...this.state,
          page,
          currentCompanyDonorsList: slicedList,
        };


    this.setState(newState);
  }

  getFilteredLists (list, filterStr) {
    return list.filter(el => el.donor.toLowerCase().includes(filterStr.toLowerCase()))
  }

  handleFilterTextInput(event) {
    this.setState({
      page: 0,
      filterText: event.target.value,
      currentPrivateDonorsList: this.getFilteredLists(this.state.privateDonorsList, event.target.value).slice(0,5),
      currentCompanyDonorsList: this.getFilteredLists(this.state.companyDonorsList, event.target.value).slice(0,5)
    });
  }

  resetCurrentLists() {
    this.setState({
      page: 0,
      filterText: "",
      currentPrivateDonorsList: this.state.privateDonorsList.slice(0, 5),
      currentCompanyDonorsList: this.state.companyDonorsList.slice(0, 5),
    });
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      donationsList,
      t,
      i18n,
      tReady,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeaderTop = {
      title: this.props.t("section_list_title"),
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="testimonial-header">
              <SectionHeader data={sectionHeaderTop} className="ta-l" />
              <div className="header-buttons">
                <Tabs active="tab-a">
                  <TabList className="mt-12 mr-12">
                    <Tab tabId="tab-a">
                      <Button
                        type="button"
                        className="text-color-high fw-400 text-sm "
                        onClick={() => this.handleShowPrivate(true)}
                      >
                        {this.props.t("section_list_tab_private")}
                      </Button>
                    </Tab>
                    <Tab tabId="tab-b">
                      <Button
                        type="button"
                        className="text-color-high fw-400 text-sm"
                        onClick={() => this.handleShowPrivate(false)}
                      >
                        {this.props.t("section_list_tab_company")}
                      </Button>
                    </Tab>
                  </TabList>
                </Tabs>
              </div>
            </div>
            <Input
            className="mb-32"
              type="search"
              label={this.props.t("section_list_search")}
              placeholder={this.props.t("section_list_search")}
              value={this.state.filterText}
              onChange={this.handleFilterTextInput}
              labelHidden
            />
            <Donors
              donationsList={
                this.state.showPrivate
                  ? this.state.currentPrivateDonorsList
                  : this.state.currentCompanyDonorsList
              }
            />
            <Button
              className="text-color-high fw-600 small"
              style={{ display: "block", margin: "32px auto" }}
              onClick={() =>
                this.handleShowMore(
                  this.state.page + 1,
                  this.state.showPrivate
                    ? this.state.privateDonorsList
                    : this.state.companyDonorsList
                )
              }
            >
              {this.props.t("section_list_all_donations_button")}
            </Button>
            {/* <TopDonation/> */}
          </div>
        </div>
      </section>
    );
  }
}

TodaysDonations.propTypes = propTypes;
TodaysDonations.defaultProps = defaultProps;

export default withTranslation("translations")(TodaysDonations);
