import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import {withTranslation} from "react-i18next";

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  const { t, i18n, tReady, ...rest } = props;

  return (
    <div
      {...rest}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={require(`./../../../assets/images/logo_${i18n.language}.svg`)}
            alt="Солидарност на дело"
            width={100}
            height={100} />
        </Link>
      </h1>
    </div>
  );
}

export default withTranslation("translations")(Logo);