import React from 'react';
import { withRouter, Switch, Redirect, Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Secondary from './views/Secondary';
import Thanks from './views/Thanks';
import Donate from './views/Donate';
import Success from './views/Success';
import Failure from './views/Failure';


class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }


  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <>
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/privacy" component={Secondary} layout={LayoutDefault} />
            {/* <AppRoute exact path="/login" component={Login} layout={LayoutSignin} /> */}
            <AppRoute exact path="/donate" component={Donate} layout={LayoutSignin} />
            <AppRoute exact path="/already-donated" component={Donate} layout={LayoutSignin} />
            <AppRoute path="/payment-fail" component={Failure} layout={LayoutSignin} />
            <AppRoute path="/payment-success" component={Success} layout={LayoutSignin} />
            <AppRoute path="/:donationId/payment-success" component={Success} layout={LayoutSignin} />
            <AppRoute path="/:donationId/payment-fail" component={Failure} layout={LayoutSignin} />
            <AppRoute path="/:donationId/thanks" component={Thanks} layout={LayoutSignin} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
          </>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);