import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  const {t, i18n, tReady, ...rest} = props;

  return (
    <nav
      {...rest}
      className={classes}
    >
      <ul className="list-reset">
      <li>
          <Link to="/privacy" target="_blank">{props.t("footer_link_privacy")}</Link>
        </li>
        <li>
          <a href="https://koronavirus.gov.mk" target="_blank">{t("footer_link_corona")}</a>
        </li>
        <li>
          <a href="http://zdravstvo.gov.mk/korona-virus/" target="_blank">{t("footer_link_mz")}</a>
        </li>
        <li>
          <a href="https://www.who.int" target="_blank">{t("footer_link_who")}</a>
        </li>
      </ul>
    </nav>
  );
}

export default withTranslation("translations")(FooterNav);