import React from "react";
import HeroSplit from "../components/sections/HeroSplit";
import TodaysDonations from "../components/sections/TodaysDonations";
import WhereDonation from "../components/sections/WhereDonation";
import Cta from "../components/sections/Cta";
import { getDefaultAPI } from "../api";
import Button from "../components/elements/Button";
import Solidarity from "../components/sections/Solidarity";
import Clients from "../components/sections/Clients";
import { withTranslation } from "react-i18next";

class Home extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      allDonors: [],
      todaysDonors: [],
      totalAmount: 0,
      totalMzAmount: 0,
      totalGsAmount: 0,
      totalDdvAmount: 0,
      totalOnlineAmount: 0,
      totalOnlineGsAmount: 0,
      totalOnlineMzAmount: 0,
      isTop: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    document.addEventListener("scroll", (e) => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop && this._isMounted) {
        this.setState({ ...this.state, isTop });
      }
    });
    try {
      const res = await getDefaultAPI().getDonationsStats();
      // const response = await fetch("/data/stats.json");
      // const res = {data: await response.json()}

      let donorsList = [];
      Object.keys(res.data.byMonth).reverse().forEach((key) => {
        donorsList = donorsList.concat(res.data.byMonth[key].donations);
      });
      if (this._isMounted) {
        this.setState({
          allDonors: donorsList,
          todaysDonors: res.data.today.donations,
          totalAmount: res.data.totalAmount,
          totalMzAmount: res.data.totalMzAmount,
          totalGsAmount: res.data.totalGsAmount,
          totalDdvAmount: res.data.totalDdvAmount,
          totalOnlineAmount: res.data.totalOnlineAmount,
          totalOnlineGsAmount: res.data.totalOnlineGsAmount,
          totalOnlineMzAmount: res.data.totalOnlineMzAmount,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  componentWillUnmount() {
    document.addEventListener("scroll", this.onScroll, false);
    this._isMounted = false;
  }

  render() {
    return (
      <React.Fragment>
        <a className="anchor" id="top"></a>
        <HeroSplit
          invertColor
          totalAmount={this.state.totalAmount}
          totalMzAmount={this.state.totalMzAmount}
          totalGsAmount={this.state.totalGsAmount}
          totalDdvAmount={this.state.totalDdvAmount}
          totalOnlineAmount={this.state.totalOnlineAmount}
          totalOnlineGsAmount={this.state.totalOnlineGsAmount}
          totalOnlineMzAmount={this.state.totalOnlineMzAmount}
        />
        <a className="anchor" id="where"></a>
        <WhereDonation hasBgColor />
        <a className="anchor" id="donations"></a>
        <TodaysDonations donors={this.state.allDonors} />
        <a className="anchor" id="encourage"></a>
        <Cta />
        <a className="anchor" id="solidarnost"></a>
        <Solidarity />
        <Clients topDivider />
        {this.state.isTop ? null : (
          <Button
            className="button-back-to-top"
            onClick={() => window.scrollTo(0, 0)}
          >
            {this.props.t("return_top_button")}
          </Button>
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation("translations")(Home);
