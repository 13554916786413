import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { withTranslation, Trans } from "react-i18next";


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class WhereDonation extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      t,
      i18n,
      tReady,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: this.props.t("section_where_title"),
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="ta-l" />
            <div style={{paddingBottom: "10px"}}>
            <p>
            {this.props.t("section_where_p1")}
            </p>
            <p>
            {this.props.t("section_where_p2")}
            </p>
            <p>
            <Trans i18nKey="section_where_p3">
            Трошењето на средствата од донаторските сметки на Министерството за здравство и на Солидарниот фонд ќе биде целосно на увид на јавноста на веб-страницата <a href="http://koronavirus.gov.mk/" target="_blank">koronavirus.gov.mk</a> во делот за <a href="http://finansiskatransparentnost.koronavirus.gov.mk/" target="_blank">финансиска транспаретност</a>.
Користењето на средствата од овие донаторски сметки исто така може да се следи и преку веб-страницата <a href="https://open.finance.gov.mk/" target="_blank">Отворени финансии</a> која јавно ги покажува податоците за реализација на трансакциите на сите буџетски корисници на Буџетот на Република Северна Македонија, со кои што располага Трезорот. 
При пребарување на Отворени финансии во категоријата „сметка на давател“ треба да внесете 0400100078785С9 за проверка на користењето на Солидарниот фонд и 1900100434785K2 за проверка на користењето од средствата донирани на Министерството за здравство. 
            
            </Trans>
            </p>
            </div>
            <hr/>
          </div>
        </div>
      </section>
    );
  }
}

WhereDonation.propTypes = propTypes;
WhereDonation.defaultProps = defaultProps;

export default withTranslation("translations")(WhereDonation);