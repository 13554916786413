import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import NumberFormat from "react-number-format";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { withTranslation } from "react-i18next";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class HeroSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      pushLeft,
      totalAmount,
      totalMzAmount,
      totalGsAmount,
      totalDdvAmount,
      totalOnlineAmount,
      totalOnlineGsAmount,
      totalOnlineMzAmount,
      t,
      i18n,
      tReady,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "hero section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      // invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "hero-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      invertColor && "invert-color"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    return (
      <section {...props} className={outerClasses}>
        <div className="carousel-wrapper">
          <Carousel
            autoPlay
            infiniteLoop
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            displayName="Ова е дисплеј нејм!!"
          >
            {[...Array(7)].map((item, index) => (
              <div key={index}>
                <Image
                  src={require(`./../../assets/images/header_image_0${
                    index + 1
                  }.jpg`)}
                  alt="Hero"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="container-xs hero-content-container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div className="hero-content center-content-mobile reveal-from-top">
                  <h1 className="mt-0 mb-16 spacer-48 spacer-4-mobile">
                    {this.props.t("hero_title")}
                  </h1>
                  <hr />
                  <h3 className="mt-0 mb-32 text-color-high hero-subtitle">
                    {this.props.t("hero_subtitle")}
                  </h3>
                  <Link
                    color="primary"
                    className="button-donate button-primary"
                    to="/donate/"
                  >
                    <div className="image-wrapper">
                      <Image
                        src={require("./../../assets/images/donate.svg")}
                        alt="Солидарност на дело"
                        width={32}
                        height={32}
                      />
                    </div>
                    <div>{this.props.t("donate_button")}</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm important-numbers pt-48 pb-48">
          <div className="pb-48">
            <div className="reveal-from-bottom" data-reveal-container=".hero">
              <div className="tiles-item-inner last-tile-item">
                <div className="important-numbers-item-content">
                  <h5 className="m-0">
                    {this.props.t("number_all_donations")}
                  </h5>
                  <h3 className="features-tiles-item-donation ">
                    <NumberFormat
                      value={this.props.totalAmount + " "}
                      displayType={"text"}
                      decimalSeparator=","
                      thousandSeparator="."
                    />{" "}
                    {this.props.t("donation_currency_long")}
                  </h3>
                </div>
              </div>
            </div>

            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".hero"
              >
                <div className="tiles-item-inner">
                  <div className="important-numbers-item-content">
                    <h5 className="m-0 fw-400">{this.props.t("account_option_mz")}</h5>
                    <h3 className="features-tiles-item-donation ">
                      <NumberFormat
                        value={this.props.totalMzAmount + " "}
                        displayType={"text"}
                        decimalSeparator=","
                        thousandSeparator="."
                      />{" "}
                      {this.props.t("donation_currency_long")}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".hero"
              >
                <div className="tiles-item-inner">
                  <div className="important-numbers-item-content">
                    <h5 className="m-0 fw-400">{this.props.t("account_option_gs")}</h5>
                    <h3 className="features-tiles-item-donation ">
                    <NumberFormat
                        value={this.props.totalGsAmount + " "}
                        displayType={"text"}
                        decimalSeparator=","
                        thousandSeparator="."
                      />{" "}
                      {this.props.t("donation_currency_long")}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".hero"
              >
                <div className="tiles-item-inner last-tile-item">
                  <div className="important-numbers-item-content">
                    <h5 className="m-0 fw-400">
                      {this.props.t("account_option_ddv")}
                    </h5>
                    <h3 className="features-tiles-item-donation ">
                    <NumberFormat
                        value={this.props.totalDdvAmount + " "}
                        displayType={"text"}
                        decimalSeparator=","
                        thousandSeparator="."
                      />{" "}
                      {this.props.t("donation_currency_long")}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="reveal-from-bottom" data-reveal-container=".hero">
              <div className="tiles-item-inner last-tile-item">
                <div className="important-numbers-item-content">
                  <h5 className="m-0">
                    {this.props.t("number_online_donations")}
                  </h5>
                  <h3 className="features-tiles-item-donation ">
                    <NumberFormat
                      value={this.props.totalOnlineAmount + " "}
                      displayType={"text"}
                      decimalSeparator=","
                      thousandSeparator="."
                    />{" "}
                    {this.props.t("donation_currency_long")}
                  </h3>
                </div>
              </div>
            </div>

            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".hero"
              >
                <div className="tiles-item-inner">
                  <div className="important-numbers-item-content">
                    <h5 className="m-0 fw-400">{this.props.t("account_option_mz")}</h5>
                    <h3 className="features-tiles-item-donation ">
                      <NumberFormat
                        value={this.props.totalOnlineMzAmount + " "}
                        displayType={"text"}
                        decimalSeparator=","
                        thousandSeparator="."
                      />{" "}
                      {this.props.t("donation_currency_long")}
                    </h3>
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".hero"
              >
                <div className="tiles-item-inner last-tile-item">
                  <div className="important-numbers-item-content">
                    <h5 className="m-0 fw-400">{this.props.t("account_option_gs")}</h5>
                    <h3 className="features-tiles-item-donation ">
                    <NumberFormat
                        value={this.props.totalOnlineGsAmount + " "}
                        displayType={"text"}
                        decimalSeparator=","
                        thousandSeparator="."
                      />{" "}
                      {this.props.t("donation_currency_long")}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm banner center-content-mobile">
          <a
            href="https://finansiskatransparentnost.koronavirus.gov.mk/"
            target="_blank"
          >
            <Image
              src={require(`./../../assets/images/Finansii-Web-${i18n.language}.jpg`)}
              alt="Финансиска транспарентност"
              className="image-web"
            />
            <Image
              src={require(`./../../assets/images/Finansii-Mobile-${i18n.language}.jpg`)}
              alt="Финансиска транспарентност"
              className="image-mobile"
              width={300}
            />
          </a>
        </div>
      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default withTranslation("translations")(HeroSplit);
