import React from "react";
import GenericSection from "../components/sections/GenericSection";
import { withTranslation } from "react-i18next";

class Secondary extends React.Component {
  render() {
    const { className, topOuterDivider, topDivider, t, ...props } = this.props;

    return (
      <React.Fragment>
        <GenericSection>
          <div className="container-xs">
            <h2 className="mt-0">{this.props.t("privacy_title")}</h2>
            <p>{this.props.t("privacy_p")} </p>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default withTranslation("translations")(Secondary);
