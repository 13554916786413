import React from 'react';
import DonateForm from '../components/sections/DonateForm';

class Donate extends React.Component {
  render() {
    return (
      <DonateForm />
    );
  }
}

export default Donate;