import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Tabs, { TabList, Tab, TabPanel } from "../elements/Tabs";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { getDefaultAPI } from "../../api";
import {
  InitiateDonationRequestDonorTypeEnum,
  InitiateDonationRequestDestinationAccountEnum,
} from "@microtica/component-donations-sdk";
import { withRouter } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { withTranslation, Trans } from "react-i18next";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max - 1));
}

function getDeclarationLink(option) {
  return option === InitiateDonationRequestDestinationAccountEnum.Mz ? "/izjava_za_licni_podatoci_MZ.pdf" :
    option === InitiateDonationRequestDestinationAccountEnum.Gs ? "/izjava_za_licni_podatoci_GS.pdf" : "";
}

class DonateForm extends React.Component {
  _isDonate = true;

  constructor(props) {
    super(props);
    if (this.props.location.pathname.includes("already")) {
      this._isDonate = false;
    }
    this.state = {
      name: "",
      city: "",
      address: "",
      email: "",
      embs: "",
      edb: "",
      principal: "",
      amount: "",
      anonymous: true,
      type: InitiateDonationRequestDonorTypeEnum.Private,
      account: "",
      payment: null,
      contractUrl: "",
      quote: "",
      cities: [],
      quotesPrivate: [],
      quotesCompany: [],
      consentCorona: false,
      declarationLink: ""
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAnonymous = this.handleAnonymous.bind(this);
    this.handleDonorType = this.handleDonorType.bind(this);
    this.handleAccount = this.handleAccount.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEMBSChange = this.handleEMBSChange.bind(this);
    this.handleEDBChange = this.handleEDBChange.bind(this);
    this.handlePrincipalChange = this.handlePrincipalChange.bind(this);
    this.handleQuoteChange = this.handleQuoteChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getQuoteForDonorType = this.getQuoteForDonorType.bind(this);
  }

  componentDidMount() {
    const privateQuotes = this.props.t("quotes_private", {
      returnObjects: true,
    });
    const companyQuotes = this.props.t("quotes_company", {
      returnObjects: true,
    });
    this.setState({
      ...this.state,
      cities: this.props.t("cities_list", { returnObjects: true }),
      quotesPrivate: privateQuotes,
      quotesCompany: companyQuotes,
      quote: privateQuotes[getRandomInt(privateQuotes.length)],
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      const privateQuotes = this.props.t("quotes_private", {
        returnObjects: true,
      });
      const companyQuotes = this.props.t("quotes_company", {
        returnObjects: true,
      });
      this.setState({
        ...this.state,
        cities: this.props.t("cities_list", { returnObjects: true }),
        quotesPrivate: privateQuotes,
        quotesCompany: companyQuotes,
        quote:
          this.state.type === InitiateDonationRequestDonorTypeEnum.Private
            ? privateQuotes[getRandomInt(privateQuotes.length)]
            : companyQuotes[getRandomInt(companyQuotes.length)],
      });
    }
  }

  handleNameChange(event) {
    this.setState({
      ...this.state,
      name: event.target.value,
    });
  }

  handleCityChange(newCity) {
    this.setState({
      ...this.state,
      city: newCity.value,
    });
  }

  handleAmountChange(event) {
    this.setState({
      ...this.state,
      amount: event.target.value,
    });
  }

  handleAnonymous() {
    this.setState({
      ...this.state,
      consentCorona: !this.state.anonymous === true ? false : this.state.consentCorona,
      anonymous: !this.state.anonymous,
    });
  }

  handleDonorType(donorType) {
    this.setState({
      ...this.state,
      type: donorType,
      anonymous: donorType === InitiateDonationRequestDonorTypeEnum.Company ? false : this.state.anonymous,
      consentCorona: donorType === InitiateDonationRequestDonorTypeEnum.Company ? true : this.state.consentCorona,
      quote: this.getQuoteForDonorType(donorType),
    });
  }

  handleAccount(value) {
    this.setState({
      ...this.state,
      account: value,
      declarationLink: getDeclarationLink(value.value)
    });
  }

  handleAddressChange(event) {
    this.setState({
      ...this.state,
      address: event.target.value,
    });
  }

  handleEmailChange(event) {
    this.setState({
      ...this.state,
      email: event.target.value,
    });
  }

  handleEMBSChange(event) {
    this.setState({
      ...this.state,
      embs: event.target.value,
    });
  }

  handleEDBChange(event) {
    this.setState({
      ...this.state,
      edb: event.target.value,
    });
  }

  handlePrincipalChange(event) {
    this.setState({
      ...this.state,
      principal: event.target.value,
    });
  }

  handleQuoteChange() {
    this.setState({
      ...this.state,
      quote: this.getQuoteForDonorType(this.state.type),
    });
  }

  getQuoteForDonorType(type) {
    let value = this.state.quotesPrivate[
      getRandomInt(this.state.quotesPrivate.length)
    ];
    if (type === InitiateDonationRequestDonorTypeEnum.Company) {
      value = this.state.quotesCompany[
        getRandomInt(this.state.quotesCompany.length)
      ];
    }
    return value;
  }

  handleCoronaConsentChange() {
    this.setState({
      ...this.state,
      consentCorona: !this.state.consentCorona,
    });
  }

  handleDateChange(event) {
    this.setState({
      ...this.state,
      date: new Date(event.target.value).getTime()
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.state.city === "" || this.state.account === "") {
      alert(this.props.t("alert_all_info"));
      return;
    }

    let newDonation = {
      donor: this.state.name,
      donorType: this.state.type,
      amount: parseInt(this.state.amount),
      address: this.state.address,
      email: this.state.email,
      city: this.state.city,
      quote: this.state.quote,
      donationType: this._isDonate ? "online" : "offline",
      destinationAccount: this.state.account.value,
      consentCorona: this.state.consentCorona,
      public: !this.state.anonymous,
      date: this.state.date
    };

    if (this.state.type === InitiateDonationRequestDonorTypeEnum.Company) {
      newDonation = {
        ...newDonation,
        principal: this.state.principal,
        embs: this.state.embs,
        edb: this.state.edb,
      };
    }

    try {
      const { data: donation } = await getDefaultAPI().initiateDonation(
        newDonation
      );

      if (this._isDonate) {
        const { data: payment } = await getDefaultAPI().payDonation(
          donation.donationId
        );

        this.setState({
          ...this.setState,
          payment,
          contractUrl: donation.contractUrl,
        });
      } else {
        //offline donation - navigate to Thanks
        this.props.history.push(`/${donation.donationId}/thanks`);
      }
    } catch (error) {
      console.log("ERROR", error);
      //navigate to success
      this.props.history.push("/payment-fail");
    }
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      to,
      staticContext,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "signin section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "signin-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: this.props.t("donate_your_data"),
      paragraph: "",
    };

    const accountOptions = [
      {
        label: this.props.t("account_option_gs"),
        value: InitiateDonationRequestDestinationAccountEnum.Gs,
      },
      {
        label: this.props.t("account_option_mz"),
        value: InitiateDonationRequestDestinationAccountEnum.Mz,
      },
    ];
    if (!this._isDonate) {
      accountOptions.push({
        label: this.props.t("account_option_ddv"),
        value: InitiateDonationRequestDestinationAccountEnum.Ddv,
      });
    }

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form name="donateForm" onSubmit={this.handleSubmit}>
                    <fieldset className="mt-24">
                      <>
                        <Tabs active="tab-a">
                          <TabList>
                            <Tab tabId="tab-a">
                              <Button
                                type="button"
                                disabled={this.state.payment !== null}
                                className="text-color-high fw-600 text-sm"
                                onClick={() =>
                                  this.handleDonorType(
                                    InitiateDonationRequestDonorTypeEnum.Private
                                  )
                                }
                              >
                                {this.props.t("donate_tab_private")}
                              </Button>
                            </Tab>
                            <Tab tabId="tab-b">
                              <Button
                                type="button"
                                disabled={this.state.payment !== null}
                                className="text-color-high fw-600 text-sm"
                                onClick={() =>
                                  this.handleDonorType(
                                    InitiateDonationRequestDonorTypeEnum.Company
                                  )
                                }
                              >
                                {this.props.t("donate_tab_company")}
                              </Button>
                            </Tab>
                          </TabList>
                          <TabPanel id="tab-a">
                            <div className="mb-12">
                              <Input
                                disabled={this.state.payment !== null}
                                label={this.props.t("donate_form_name")}
                                placeholder={this.props.t(
                                  "donate_form_name"
                                )}
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                labelHidden
                                required
                              />
                            </div>
                          </TabPanel>
                          <TabPanel id="tab-b">
                            {this.state.type ===
                              InitiateDonationRequestDonorTypeEnum.Company ? (
                                <>
                                  <div className="mb-12">
                                    <Input
                                      label={this.props.t(
                                        "donate_form_company_name"
                                      )}
                                      placeholder={this.props.t(
                                        "donate_form_company_name"
                                      )}
                                      value={this.state.name}
                                      onChange={this.handleNameChange}
                                      labelHidden
                                      required
                                      disabled={this.state.payment !== null}
                                    />
                                  </div>
                                  <div className="mb-12">
                                    <Input
                                      label={this.props.t(
                                        "donate_form_principal"
                                      )}
                                      placeholder={this.props.t(
                                        "donate_form_principal"
                                      )}
                                      value={this.state.principal}
                                      onChange={this.handlePrincipalChange}
                                      labelHidden
                                      required
                                      disabled={this.state.payment !== null}
                                    />
                                  </div>
                                  <div className="mb-12">
                                    <Input
                                      label={this.props.t("donate_form_embs")}
                                      placeholder={this.props.t(
                                        "donate_form_embs"
                                      )}
                                      type="number"
                                      value={this.state.embs}
                                      onChange={this.handleEMBSChange}
                                      labelHidden
                                      required
                                      disabled={this.state.payment !== null}
                                    />
                                  </div>
                                  <div className="mb-12">
                                    <Input
                                      label={this.props.t("donate_form_edb")}
                                      placeholder={this.props.t(
                                        "donate_form_edb"
                                      )}
                                      value={this.state.edb}
                                      onChange={this.handleEDBChange}
                                      labelHidden
                                      required
                                      disabled={this.state.payment !== null}
                                    />
                                  </div>
                                </>
                              ) : null}
                          </TabPanel>
                        </Tabs>
                        <div className="mb-12">
                          <Input
                            label={this.props.t("donate_form_address")}
                            placeholder={this.props.t(
                              "donate_form_address"
                            )}
                            value={this.state.address}
                            onChange={this.handleAddressChange}
                            labelHidden
                            required
                            disabled={this.state.payment !== null}
                          />
                        </div>
                        <div className="mb-12">
                          <Input
                            label={this.props.t("donate_form_email")}
                            placeholder={this.props.t(
                              "donate_form_email"
                            )}
                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                            title="Should be a valid email address"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            labelHidden
                            required
                            disabled={this.state.payment !== null}
                          />
                        </div>
                        {
                          !this._isDonate ?
                            <div className="mb-12">
                              <Input
                                title="Should be a valid date"
                                type="date"
                                onChange={this.handleDateChange}
                                labelHidden
                                disabled={this.state.payment !== null}
                              />
                            </div> : null
                        }
                        <div className="mb-12">
                          <Dropdown
                            required
                            disabled={this.state.payment !== null}
                            className="pt-8 text-xs"
                            options={this.state.cities}
                            onChange={this.handleCityChange}
                            value={this.state.city}
                            label={this.props.t("donate_form_city")}
                            placeholder={this.props.t("donate_form_city")}
                          />
                        </div>
                      </>

                      <div
                        className="mb-12"
                      >
                        <Input
                          type="number"
                          label={this.props.t("donate_form_sum")}
                          placeholder={this.props.t("donate_form_sum")}
                          value={this.state.amount}
                          min="100"
                          onChange={this.handleAmountChange}
                          labelHidden
                          required
                          disabled={this.state.payment !== null}
                        />
                      </div>
                      <div className="mt-12">
                        <Dropdown
                          required
                          disabled={this.state.payment !== null}
                          className="pt-8 text-xs"
                          options={accountOptions}
                          onChange={this.handleAccount}
                          value={this.state.account}
                          placeholder={this.props.t("donate_form_account")}
                          label={this.props.t("donate_form_account")}
                        />
                      </div>
                      <div className="mt-24 text-color-high">
                        <Image
                          style={{
                            position: "absolute",
                            marginLeft: "125px",
                            marginTop: "5px",
                            cursor: "pointer",
                            pointerEvents: this.state.payment ? 'none' : 'auto'
                          }}
                          alt="Нова порака"
                          onClick={() =>
                            this.handleQuoteChange(this.state.type)
                          }
                          disabled={this.state.payment !== null}
                          width={15}
                          height={15}
                          src={require("../../assets/images/refresh.svg")}
                        />
                        <div>
                          <label className="text-xxs text-color-medium">
                            {this.props.t("donate_form_quote_label")}
                          </label>
                          <p className="text-xs text-color-medium p-12 border-secondary">
                            {this.state.quote}
                          </p>
                        </div>
                      </div>
                      {this.state.payment ? null : (
                        <>
                          <div className="mt-24 mb-32">
                            {
                              this.state.type === InitiateDonationRequestDonorTypeEnum.Private ?
                                <>
                                  <div className="mt-24 mb-32 text-xs text-color-low">
                                    <input
                                      type="checkbox"
                                      id="consent"
                                      onChange={() => this.handleAnonymous()}
                                      checked={!this.state.anonymous}
                                    />
                                    <label for="consent">
                                      {" "}
                                      {this.props.t("donate_anonymous")}{" "}
                                      <a
                                        href="https://solidarnost.gov.mk"
                                        target="_blank"
                                      >
                                        solidarnost.gov.mk
                                </a>
                                    </label>
                                  </div>
                                  <div className="mt-24 mb-32 text-xs text-color-low">
                                    <input
                                      type="checkbox"
                                      id="consentCorona"
                                      onChange={() =>
                                        this.handleCoronaConsentChange()
                                      }
                                      checked={this.state.consentCorona}
                                      disabled={this.state.anonymous}
                                    />
                                    <label for="consentCorona">
                                      {" "}
                                      {this.props.t(
                                        "donate_form_consent_corona"
                                      )}{" "}
                                      <a
                                        href="https://finansiskatransparentnost.koronavirus.gov.mk"
                                        target="_blank"
                                      >
                                        finansiskatransparentnost.koronavirus.gov.mk
                                </a>
                                    </label>
                                  </div>
                                </> : null
                            }
                            <Button
                              color="primary"
                              wide
                              type="submit"
                              disabled={this.state.payment !== null}
                            >
                              {this.props.t("donate_form_submit_button")}
                            </Button>
                            <div className="p-8 text-xs text-color-low fw-700">
                              * {this.props.t("donate_form_consent_ddv")}
                            </div>
                            {this.state.type === InitiateDonationRequestDonorTypeEnum.Private
                              && this.state.account !== "" ? (
                                <div className="p-8 text-xs text-color-low">
                                  * {" "}
                                  <Trans i18nKey="donate_form_consent">
                                    Со потврдување на податоците се согласувам со
                                  условите во {" "}
                                    <a href={this.state.declarationLink}
                                      target="_blank"
                                    >
                                      Изјавата
                                    </a>
                                    за согласност за обработка и објавување на лични податоци.
                                </Trans>
                                </div>
                              ) : null}
                          </div>
                        </>
                      )}
                    </fieldset>
                  </form>
                  {/* CPay form */}
                  {this.state.payment ? (
                    <form
                      name="paymentForm"
                      action={this.state.payment.redirectUrl}
                      method="post"
                      id="PaymentForm"
                    >
                      <input
                        type="hidden"
                        id="AmountToPay"
                        name="AmountToPay"
                        value={this.state.payment.amount}
                      />
                      <input
                        type="hidden"
                        id="AmountCurrency"
                        name="AmountCurrency"
                        value={this.state.payment.currency}
                      />
                      <input
                        type="hidden"
                        id="Details1"
                        name="Details1"
                        value={this.state.payment.description}
                      />
                      <input
                        type="hidden"
                        id="Details2"
                        name="Details2"
                        value={this.state.payment.orderId}
                      />
                      <input
                        type="hidden"
                        id="PayToMerchant"
                        name="PayToMerchant"
                        value={this.state.payment.marchantId}
                      />
                      <input
                        type="hidden"
                        id="MerchantName"
                        name="MerchantName"
                        value={this.state.payment.marchantName}
                      />
                      <input
                        type="hidden"
                        id="PaymentOKURL"
                        name="PaymentOKURL"
                        value={this.state.payment.successUrl}
                      />
                      <input
                        type="hidden"
                        id="PaymentFailURL"
                        name="PaymentFailURL"
                        value={this.state.payment.failUrl}
                      />

                      <input
                        type="hidden"
                        id="CheckSumHeader"
                        name="CheckSumHeader"
                        value={this.state.payment.checksumHeader}
                      />
                      <input
                        type="hidden"
                        id="CheckSum"
                        name="CheckSum"
                        value={this.state.payment.checksum}
                      />

                      {/* <div className="mt-24 mb-0 pl-8 text-sm text-color-high">
                        <a href={this.state.contractUrl}>{this.props.t("donate_form_contract")}</a>
                      </div> */}
                      <div className="mt-12 mb-32">
                        <Button color="primary" wide type="submit">
                          {this.props.t("donate_button")}
                        </Button>
                      </div>
                    </form>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

DonateForm.propTypes = propTypes;
DonateForm.defaultProps = defaultProps;

export default withRouter(withTranslation("translations")(DonateForm));
