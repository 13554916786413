import React from "react";
import classNames from "classnames";
import NumberFormat from "react-number-format";
import { withTranslation } from "react-i18next";
import moment from "moment";
import Input from "../elements/Input";

class DonorListItem extends React.Component {
  render() {
    const { t, ...props } = this.props;

    return (
      <div
        className="tiles-item-parent"
        style={{ boxSizing: "content-box", padding: "12px" }}
      >
        <div className="tiles-item-inner ">
          <div className="testimonial-item-header">
            <div className="testimonial-item-amount text-color-high ">
              <NumberFormat
                value={this.props.donor.amount}
                displayType={"text"}
                decimalSeparator=","
                thousandSeparator="."
              />{" "}
              {this.props.t("donation_currency_short")}{" "}
              <small>
                {this.props.donor.destinationAccount === "gs"
                  ? this.props.t("for_gs")
                  : this.props.donor.destinationAccount === "mz"
                  ? this.props.t("for_mz")
                  : this.props.t("from_ddv")}
              </small>
              {/* {" ден"} */}
            </div>
          </div>
          <div className="testimonial-item-footer fw-500 mb-0">
            <div className="testimonial-item-content">
              <p className="mb-0">{"- " + this.props.donor.quote}</p>
            </div>
            <span className="testimonial-item-name fw-600">
              {this.props.donor.donor === "anonymous"
                ? this.props.t("anonymous")
                : this.props.donor.donor}
            </span>
            <span className="text-color-low"> / </span>
            <span className="testimonial-item-link fw-600">
              {this.props.donor.city}
            </span>
            <span className="text-color-low"> / </span>
            {moment(this.props.donor.date).format("DD.MM.YYYY")}
          </div>
        </div>
      </div>
    );
  }
}

class DonorsList extends React.Component {
  render() {
    var listItems = [];
    this.props.donations.forEach((donation, index) => {
      if (donation.donor.includes(this.props.filterText) === false) {
        return;
      }
      listItems.push(
        <DonorListItem key={index} donor={donation} t={this.props.t} />
      );
    });
    return <div className={classNames.table}>{listItems}</div>;
  }
}

class FilterableDonorTable extends React.Component {
  constructor(props) {
    super(props);
    // FilterableContactTable is the owner of the state as the filterText is needed in both nodes (searchbar and table) that are below in the hierarchy tree.
    this.state = {
      filterText: "",
    };

    this.handleFilterTextInput = this.handleFilterTextInput.bind(this);
  }

  handleFilterTextInput(event) {
    console.log("handleFilterTextInput", event.target.value)
    //Call to setState to update the UI
    this.setState({
      filterText: event.target.value,
    });
    //React knows the state has changed, and calls render() method again to learn what should be on the screen
  }

  render() {
    return (
      <div>
        <DonorsList
          donations={this.props.donations}
          filterText={this.state.filterText}
          t={this.props.t}
        />
      </div>
    );
  }
}

class Donors extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FilterableDonorTable
        donations={this.props.donationsList}
        t={this.props.t}
      />
    );
  }
}

export default withTranslation("translations")(Donors);
