import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Clients extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'clients section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'clients-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div
            className={innerClasses}
          >
            <ul className="list-reset">
              <li >
              <a href="https://finance.gov.mk/" target="_blank">
                <Image
                  src={require('./../../assets/images/mfinansii.png')}
                  alt="Logo 01"
                  width={320}
                  height={39} />
                  </a>
              </li>
              <li >
              <a href="https://www.usaid.gov/" target="_blank">
                <Image
                  src={require('./../../assets/images/usaid.svg')}
                  alt="Logo 02"
                  width={110}
                  height={39} />
                  </a>
              </li>
              <li >
              <a href="https://www.iri.org/" target="_blank">
                <Image
                  src={require('./../../assets/images/iri.png')}
                  alt="Logo 03"
                  width={120}
                  height={39} />
                  </a>
              </li>
              <li >
              <a href="http://www.unibank.com.mk/" target="_blank">
                <Image
                  src={require('./../../assets/images/uni_banka.png')}
                  alt="Logo 04"
                  width={120}
                  height={39} />
                  </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;