import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import FooterSocial from '../layout/partials/FooterSocial';
import Button from '../elements/Button';
import { Link } from "react-router-dom";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import {withTranslation} from "react-i18next";



const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

class Cta extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      t,
      i18n,
      tReady,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="cta-container">
          <div
            className={innerClasses}
          >
            <div className="cta-slogan">
              <h2 className="m-0 mb-12">
                {this.props.t("section_donated_title")}
              </h2>
              <hr/>
              <p>{this.props.t("section_donated_p")}</p>
            </div>
            <div className="cta-action">
              {/* <FooterSocial /> */}
              <Link className="button button-secondary" to="/already-donated/">{this.props.t("section_donated_button")}</Link>
            </div>
          </div>
        </div>
        <div className="cta-divider"></div>
        <div className="cta-container">
          <div
            className={innerClasses}
          >
            <div className="cta-slogan">
              <h2 className="m-0 mb-12">
              {this.props.t("section_encourage_title")}
              </h2>
              <hr/>
              <p>{this.props.t("section_encourage_p")}</p>
            </div>
            <div className="cta-action">
              <FooterSocial  />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default withTranslation("translations")(Cta);