import { DefaultApi } from '@microtica/component-donations-sdk';


// axios.defaults.headers.common['Content-Type'] = 'application/json';


// type WindowConfig = Window & { env: { BACKEND_ENDPOINT: string } }

export function getBaseUrl() {
    if (window.env) {
        return window.env.BACKEND_ENDPOINT;
    } else {
        return "http://localhost:80";
    }
};

export function getDefaultAPI() {
    return new DefaultApi({
        basePath: `${getBaseUrl()}`
    });
}