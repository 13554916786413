import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { HashLink as Link } from "react-router-hash-link";
import Logo from "./partials/Logo";
import { withTranslation } from "react-i18next";

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  active: false,
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

class Header extends React.Component {
  state = {
    isActive: false,
    language: localStorage.getItem("language") || "mk",
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener("keydown", this.keyPress);
    document.addEventListener("click", this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
    document.addEventListener("click", this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + "px";
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  };

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  };

  clickOutside = (e) => {
    if (!this.nav.current) return;
    if (
      !this.state.isActive ||
      this.nav.current.contains(e.target) ||
      e.target === this.hamburger.current
    )
      return;
    this.closeMenu();
  };

  handleChangeLanguage(language) {
    this.props.i18n.changeLanguage(language) && this.closeMenu();

    localStorage.setItem("language", language);
  }

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      hasBgColor,
      t,
      i18n,
      tReady,
      ...props
    } = this.props;

    const classes = classNames(
      "site-header",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      className
    );

    return (
      <header {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-header-inner",
              bottomDivider && "has-bottom-divider",
              hasBgColor && "has-bg-color"
            )}
          >
            {!hideNav && (
              <React.Fragment>
                <Logo
                  className="header-nav-toggle"
                  onClick={() => window.scrollTo(0, 0)}
                />
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={this.nav}
                  className={classNames(
                    "header-nav",
                    this.state.isActive && "is-active"
                  )}
                >
                  <div className="header-nav-inner">
                    <ul
                      className={classNames(
                        "list-reset text-sm",
                        navPosition && `header-nav-${navPosition}`
                      )}
                    >
                      <li>
                        <Link to="/#where" onClick={this.closeMenu}>
                          {this.props.t("header_where")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/#donations" onClick={this.closeMenu}>
                          {this.props.t("header_list")}
                        </Link>
                      </li>
                      <Logo
                        className="header-nav-toggle-reverse"
                        onClick={() => window.scrollTo(0, 0)}
                      />
                      <li>
                        <Link to="/#encourage" onClick={this.closeMenu}>
                          {this.props.t("header_donated")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/#encourage" onClick={this.closeMenu}>
                          {this.props.t("header_encourage")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="text-sm tab-list mt-4 language-content">
                    {this.props.i18n.language === "mk" ? (
                      <>
                        <div
                          className="pr-12 tab"
                          onClick={() => this.handleChangeLanguage("en")}
                        >
                          EN
                        </div>
                        <div
                          className="tab"
                          onClick={() => this.handleChangeLanguage("al")}
                        >
                          AL
                        </div>
                      </>
                    ) : this.props.i18n.language === "en" ? (
                      <>
                        <div
                          className="pr-12 tab"
                          onClick={() => this.handleChangeLanguage("mk")}
                        >
                          MK
                        </div>
                        <div
                          className="tab"
                          onClick={() => this.handleChangeLanguage("al")}
                        >
                          AL
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="pr-12 tab"
                          onClick={() => this.handleChangeLanguage("en")}
                        >
                          EN
                        </div>
                        <div
                          className="tab"
                          onClick={() => this.handleChangeLanguage("mk")}
                        >
                          MK
                        </div>
                      </>
                    )}
                  </div>
                </nav>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default withTranslation("translations")(Header);
