import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { withTranslation, Trans } from "react-i18next";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Solidarity extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      t,
      tReady,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "news section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "news-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: this.props.t("section_solidarity_title"),
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="ta-l" />
            <div style={{ paddingBottom: "10px" }}>
              <p>
                <Trans i18nKey="section_solidarity_p1">
                  Веб-страницата solidarnost.gov.mk овозможува онлајн донирање
                  од физички и правни лица во фондовите за справување со кризата
                  предизвикана од коронавирусот КОВИД-19 на Министерството за
                  здравство и Солидарниот фонд (
                  <a
                    href="https://vlada.mk/sites/default/files/dokumenti/Uredbi_Covid19/44_Sednica_18_21-04-2020/sednica_44_18.03.2020_uredba_so_zakonska_sila_za_formiranje_fond_za_pomosh_i_poddrshka_za_spravuvanje_so_krizata_predizvikana_od_koronavirusot_covid-19.pdf"
                    target="_blank"
                  >
                    Фонд за помош и поддршка за справување со кризата
                    предизвикана од коронавирусот КОВИД-19
                  </a>
                  ) при Владата на Република Северна Македонија. Донираните
                  средства на донаторската сметка на Министерството за здравство
                  се користат за набавка на медицински материјал и опрема, а
                  донираните средства во Солидарниот фонд се користат за помош и
                  поддршка на социоекономските мерки за надминување на
                  економската криза предизвикана од корона вирусот КОВИД-19. При
                  донирањето, физичките лица имаат можност да одберат дали
                  нивното име, презиме и општина ќе бидат јавно објавени. Сите
                  донации дадени во овие фондови се донации за кои согласно
                  <a
                    href="https://vlada.mk/sites/default/files/dokumenti/Uredbi_Covid19/43_Sednica_16-04-2020/sednica_43_16.04.2020_uredba_so_zakonska_sila_za_primena_na_zakonot_za_donacii_i_za_sponzorstva_vo_javnite_dejnosti.pdf"
                    target="_blank"
                  >
                    уредба со законска сила
                  </a> 
                  е утврден јавниот интерес. За секоја донација во овие фондови
                  донаторите можат да остварат даночно ослободување преку
                  Управата за јавни приходи најдоцна до 31 јануари во годината
                  што следи по годината кога ги донирале средствата.
                </Trans>
              </p>

              <p>
                <Trans i18nKey="section_solidarity_p2">
                  Финансиска транспарентност за добиените донации и направените
                  расходи на институциите во борбата против епидемијата на
                  корона вирусот наскоро ќе бидат објавени на веб-страницата
                  <a href="http://finansiskatransparentnost.koronavirus.gov.mk/"
                    target="_blank">finansiskatransparentnost.koronavirus.gov.mk</a>. Со ова ќе се
                  обезбеди целосна транспарентност и отчетност за донациите,
                  исплатите и набавките на институциите на централно ниво во
                  справувањето со епидемијата предизвикана од
                  КОВИД-19.Доколку имате потреба од дополнителни информации
                  во врска со веб-страницата solidarnost.gov.mk контајтирате нѐ
                  на solidarnost@finance.gov.mk.
                </Trans>
              </p>
              <p>
                <Trans i18nKey="section_solidarity_p3">
                  <a href="https://www.iri.org/" target="_blank">
                    Меѓународниот републикански институт
                  </a>(ИРИ) и 
                  <a href="https://finance.gov.mk/" target="_blank">
                    Министерството за финансии
                  </a>
                  изразуваат благодарност до 
                  <a href="http://www.unibank.com.mk/" target="_blank">
                    УНИБАНКА А.Д.
                  </a>
                  Скопје која се откажа од наплата на провизии или други
                  банкарски трошоци за уплатите на сите донации кои ќе се
                  остварат преку оваа веб-страница, <a href="https://mia.mk/" target="_blank">Македонската информативна
                  агенција</a> којашто без надомест отстапи авторски фотографии што
                  се користат за оваа веб-страница. Оваа веб-страница е
                  овозможена од Меѓународниот републикански институт (ИРИ), со
                  поддршка на американскиот народ преку 
                  <a href="https://www.usaid.gov/" target="_blank">
                    Агенцијата на САД за меѓународен развој
                  </a> 
                  (УСАИД). Содржините на веб-страницата solidarnost.gov.mk не ги
                  изразуваат ставовите на Агенцијата на САД за меѓународен
                  развој или на Владата на Соединетите Американски Држави.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Solidarity.propTypes = propTypes;
Solidarity.defaultProps = defaultProps;

export default withTranslation("translations")(Solidarity);
