
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import translations_mk from "./locales/mk/translation.json";
import translations_en from "./locales/en/translation.json";
import translations_al from "./locales/al/translation.json";

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      mk: {
        translations: translations_mk
      },
      en: {
        translations: translations_en
      },
      al: {
        translations: translations_al
      },
    },
    fallbackLng: "mk",
    debug: false,
    reload: ["en", "al"],
    lng: localStorage.getItem("language") || "mk",
    languages: ["mk", "en", "al"],

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    },

    react: {
      wait: true
    }

  });


export default i18n;
