import React from "react";
import classNames from "classnames";
import SectionHeader from "../components/sections/partials/SectionHeader";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Failure extends React.Component {
  render() {
    const outerClasses = classNames("signin section");

    const innerClasses = classNames("signin-inner");

    const sectionHeader = {
      title: this.props.t("donate_fail_title"),
      paragraph: this.props.t("donate_fail_subtitle"),
    };

    return (
      <section className={outerClasses}>
        <div className="container-xs">
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <Link to="/donate" className="button button-primary">
                {this.props.t("donate_button")}
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("translations")(Failure);
