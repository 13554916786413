import React from "react";
import classNames from "classnames";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  const shareUrl = "https://solidarnost.gov.mk";

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <FacebookShareButton image="https://solidarnost.gov.mk/static/media/header_image_04.32276b34.png" url={shareUrl} quote={props.quote} className="footer-social-button">
            <FacebookIcon size="45" round bgStyle={{fill: "transparent"}} iconFillColor="#eb5d51" />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={shareUrl} title={props.quote} className="footer-social-button">
            <TwitterIcon size="45" round bgStyle={{fill: "transparent"}} iconFillColor="#eb5d51"/>
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton url={shareUrl} className="footer-social-button">
            <LinkedinIcon size="45" round bgStyle={{fill: "transparent"}} iconFillColor="#eb5d51"/>
          </LinkedinShareButton>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
