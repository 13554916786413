import React from 'react';
import classNames from "classnames";
import SectionHeader from "../components/sections/partials/SectionHeader";
import FooterSocial from '../components/layout/partials/FooterSocial';
import { withTranslation } from "react-i18next";
import { getDefaultAPI } from "../api";
import { withRouter } from "react-router-dom";


class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractUrl: "",
      quote: ""
    };
  }

  async componentDidMount() {
    try {
      if (!this.props.match.params.donationId) {
        return;
      }
      
      const res = await getDefaultAPI().getDonationContract(this.props.match.params.donationId)
      this.setState({
        contractUrl: res.data.downloadUrl,
        quote: res.data.donation.quote
      });

      // Automatically download contract document
      const a = document.createElement('A');
      a.href = res.data.downloadUrl;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log("error", error);
    }
  }

  render() {
    const outerClasses = classNames(
      "signin section",
    );

    const innerClasses = classNames(
      "signin-inner",
    );

    const sectionHeader = {
      title: this.props.t("donate_success_title"),
      paragraph: this.props.t("donate_success_subtitle_online"),
    };

    return (
      <section className={outerClasses}>
        <div className="container-xs">
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <FooterSocial quote={this.state.quote} />

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(withTranslation("translations")(Success));